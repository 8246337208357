.App {
    text-align: center;
}

.App-logo {
    height: 10vmin;
    pointer-events: none;
}


/* @media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 10s linear;
    }
} */

.App-header {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.App-link {
    color: #61dafb;
}


/* @keyframes App-logo-spin {
    0% {
        transform: scale(0.75);
    }
    20% {
        transform: scale(1);
    }
    40% {
        transform: scale(0.75);
    }
    60% {
        transform: scale(1);
    }
    80% {
        transform: scale(0.75);
    }
    100% {
        transform: scale(1);
    }
} */